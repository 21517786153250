/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Components
 */
import RecordHeader from "./recordheader";
import NoteTypeContainer from "./notetypecontainer";
import PatientContextContainer from "./patientcontextcontainer";
import RecordingContainer from "./recordingcontainer";
import RecordingContainerNotEnglish from "./recordingcontainernotenglish";

/**
 * Internal Dependencies
 * - Styles
 * - Redux
 */
import "./framerecord.css";
import { useSelector } from "react-redux";
import MicrophoneTestContainer from "./microphonetestcontainer";
import LanguageTypeContainer from "./languagetypecontainer";

/**
 * FrameRecord component for managing the recording frame.
 * @param {object} props - The component props.
 * @param {string} props.rootClassName - The root class name for styling.
 * @returns {JSX.Element} The FrameRecord component.
 */
const FrameRecord = ({ rootClassName }) => {
  /**
   * @userIsRecording - Flag to determine if the user is recording.
   * @currentVisitLanguageEnglish - Flag to determine if the user is recording in english or not.
   */
  const userIsRecording = useSelector(
    (state) => state.globalData.userData.userIsRecording,
  );

  const currentVisitLanguageEnglish = useSelector(
    (state) =>
      (state.globalData.userData.visits.find(
        (visit) => visit._id === state.globalData.currentState.currentVisitId,
      )?.visitLanguage ?? "ENGLISH") === "ENGLISH",
  );

  return (
    <>
      {userIsRecording && <div className="blur-background"></div>}
      <div className={`framerecord-framerecord ${rootClassName}`}>
        <RecordHeader className="filterblur" />
        <NoteTypeContainer className="filterblur" />
        <LanguageTypeContainer className="filterblur" />
        <PatientContextContainer className="filterblur" />
        <div className="framerecord-divider"></div>
        <MicrophoneTestContainer className="filterblur" />
        {currentVisitLanguageEnglish ? (
          <RecordingContainer className="filterblur" />
        ) : (
          <RecordingContainerNotEnglish className="filterblur" />
        )}
      </div>
    </>
  );
};

FrameRecord.defaultProps = {
  rootClassName: "",
};

FrameRecord.propTypes = {
  rootClassName: PropTypes.string,
};

export default FrameRecord;
