/**
 * External Dependencies
 */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

/**
 * Internal Components
 */
import Button from "../../shared/button";
import PausedButton from "./pausedbutton";
import RecordingButton from "./recordingbutton";
import FinishButton from "./finishbutton";
import DarkButton from "../../shared/darkbutton";

/**
 * Internal Dependencies
 * - Styles
 * - Custom Hooks
 * - Redux Actions
 */
import "./recordingcontainer.css";
import { useWhisper } from "@chengsokdara/use-whisper";
import {
  setVisit,
  setCurrentState,
  setNotification,
  setRecordingState,
} from "../../../redux/actions";
import { generateId } from "../../../scripts/apiService";
import { generateName } from "../../../scripts/generateName";

/**
 * RecordingContainerNotEnglish component for managing the recording process.
 * @returns {JSX.Element} The RecordingContainerNotEnglish component.
 */
const RecordingContainerNotEnglish = () => {
  /**
   * Redux State and Dispatch Functions
   */
  const dispatch = useDispatch();
  const visits = useSelector((state) => state.globalData.userData.visits);
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const visitTypes = useSelector(
    (state) => state.globalData.userData.visitTypes,
  );
  const currentVisit = visits.find((visit) => visit._id === currentVisitId);

  /**
   * Language ISO Map for setting transcription language codes
   */
  const LANGUAGE_ISO_MAP = {
    ENGLISH: "en",
    ENGLISH2: "en",
    SPANISH: "es",
    KOREAN: "ko",
    PUNJABI: "pa",
    CHINESE: "zh",
    HINDI: "hi",
    ARABIC: "ar",
    FRENCH: "fr",
    OTHER: "other",
  };
  const selectedLanguageISO =
    LANGUAGE_ISO_MAP[currentVisit?.visitLanguage || "ENGLISH"];

  /**
   * State Variables
   */
  const [recordingStatus, setRecordingStatus] = useState("start");
  const [elapsedTime, setElapsedTime] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState("Loading");
  const [isFinishClicked, setIsFinishClicked] = useState(false);
  const timerRef = useRef(null);

  /**
   * Whisper Hook for Recording
   */
  const { transcript, startRecording, stopRecording, recording } = useWhisper({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    streaming: false,
    autoTranscribe: true,
    mode: "transcriptions",
    whisperConfig: {
      language: selectedLanguageISO,
    },
    onTranscribe: transcribeAudio,
  });

  /**
   * Handles the transcription of the recording blob using AssemblyAI.
   * @param {Blob} blob - The audio blob to transcribe.
   * @returns {Object} - An object containing the audio blob and the transcript text.
   */
  async function transcribeAudio(blob) {
    try {
      const API_KEY = process.env.REACT_APP_ASSEMBLY_API_KEY;
      const UPLOAD_URL = "https://api.assemblyai.com/v2/upload";
      const TRANSCRIPT_URL = "https://api.assemblyai.com/v2/transcript";

      console.debug("Starting transcription process...");

      // Step 1: Upload the audio file to AssemblyAI
      const audioUrl = await uploadAudio(blob, API_KEY, UPLOAD_URL);
      console.debug("Audio uploaded successfully. Audio URL:", audioUrl);

      // Step 2: Send the audio URL to the transcription endpoint
      const transcriptId = await requestTranscription(
        audioUrl,
        API_KEY,
        TRANSCRIPT_URL,
      );
      console.debug(
        "Transcription request successful. Transcript ID:",
        transcriptId,
      );

      // Step 3: Polling for transcription status
      const transcriptText = await checkTranscriptionStatus(
        transcriptId,
        API_KEY,
        TRANSCRIPT_URL,
      );

      console.debug("Returning transcription result.");
      return {
        blob,
        text: transcriptText,
      };
    } catch (error) {
      console.error("Error during transcription:", error);
      throw error;
    }
  }

  /**
   * Upload audio to AssemblyAI with retry logic.
   */
  async function uploadAudio(blob, apiKey, uploadUrl, retries = 10) {
    const file = new File([blob], "audio.mp3", { type: "audio/mp3" });
    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        console.debug(`Uploading audio file, attempt ${attempt}...`);
        const response = await fetch(uploadUrl, {
          method: "POST",
          headers: {
            Authorization: apiKey,
            "Content-Type": "application/octet-stream",
          },
          body: file,
        });
        if (response.ok) {
          const uploadData = await response.json();
          return uploadData.upload_url;
        } else {
          console.warn(
            `Upload attempt ${attempt} failed:`,
            response.statusText,
          );
        }
      } catch (error) {
        console.error(`Upload attempt ${attempt} encountered an error:`, error);
      }
      if (attempt < retries) {
        console.debug("Retrying upload...");
      } else {
        throw new Error("Failed to upload audio file after multiple attempts.");
      }
    }
  }

  /**
   * Request transcription from AssemblyAI with retry logic.
   */
  async function requestTranscription(
    audioUrl,
    apiKey,
    transcriptUrl,
    retries = 10,
  ) {
    const transcriptionRequest = {
      audio_url: audioUrl,
      language_code: selectedLanguageISO,
      speaker_labels: true,
      format_text: true,
      punctuate: true,
      speech_model: "best",
    };
    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        console.debug(`Requesting transcription, attempt ${attempt}...`);
        const response = await fetch(transcriptUrl, {
          method: "POST",
          headers: {
            Authorization: apiKey,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(transcriptionRequest),
        });
        if (response.ok) {
          const transcriptionData = await response.json();
          return transcriptionData.id;
        } else {
          console.warn(
            `Transcription request attempt ${attempt} failed:`,
            response.statusText,
          );
        }
      } catch (error) {
        console.error(
          `Transcription request attempt ${attempt} encountered an error:`,
          error,
        );
      }
      if (attempt < retries) {
        console.debug("Retrying transcription request...");
      } else {
        throw new Error(
          "Failed to request transcription after multiple attempts.",
        );
      }
    }
  }

  /**
   * Check the transcription status from AssemblyAI.
   */
  async function checkTranscriptionStatus(transcriptId, apiKey, transcriptUrl) {
    let transcriptStatus = null;
    let transcriptText = null;
    console.debug("Checking transcription status...");
    while (true) {
      try {
        const statusResponse = await fetch(`${transcriptUrl}/${transcriptId}`, {
          method: "GET",
          headers: {
            Authorization: apiKey,
          },
        });
        if (!statusResponse.ok) {
          throw new Error("Failed to get transcription status.");
        }
        const statusData = await statusResponse.json();
        transcriptStatus = statusData.status;
        console.debug("Current transcription status:", transcriptStatus);
        if (transcriptStatus === "completed") {
          transcriptText = statusData.text;
          console.debug("Transcription completed successfully.");
          break;
        } else if (transcriptStatus === "failed") {
          throw new Error("Transcription failed.");
        }
      } catch (error) {
        console.error("Error checking transcription status:", error);
      }
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }
    return transcriptText;
  }

  /**
   * useEffect to manage recording state and update timer during recording.
   */
  useEffect(() => {
    if (recordingStatus === "recording") {
      timerRef.current = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }
    return () => clearInterval(timerRef.current);
  }, [recordingStatus]);

  /**
   * useEffect to handle availability of transcript after recording stops.
   */
  useEffect(() => {
    if (transcript.text || transcript.text === "" || transcript.text === " ") {
      handleTranscriptAvailable();
    }
  }, [transcript]);

  /**
   * useEffect to handle elapsed time and auto-pause recording after certain time intervals.
   */
  useEffect(() => {
    if (recordingStatus === "recording") {
      dispatch(setVisit(currentVisitId, { visitAudioTime: elapsedTime }));
    }
    if (elapsedTime === 5400) {
      setElapsedTime(5401);
      dispatch(setVisit(currentVisitId, { visitAudioTime: 5401 }));
      handlePauseClick();
    } else if (elapsedTime === 5100) {
      dispatch(
        setNotification({
          name: "Auto-Pause",
          description:
            "This encounter will be paused in 5 minutes. To resume recording, click play.",
          status: "warning",
          duration: null,
          isClosable: true,
        }),
      );
    }
  }, [elapsedTime]);

  /**
   * useEffect to set the initial recording state based on visit data and reset state on component unmount.
   */
  useEffect(() => {
    dispatch(setRecordingState(false));
    if (currentVisit.visitAudioTime === 0) {
      setRecordingStatus("start");
    } else {
      setRecordingStatus("paused");
      setElapsedTime(currentVisit.visitAudioTime);
    }
    return () => {
      dispatch(setRecordingState(false));
    };
  }, [currentVisitId]);

  /**
   * useEffect to update loading message while status is 'loading'.
   */
  useEffect(() => {
    if (recordingStatus === "loading") {
      const interval = setInterval(() => {
        setLoadingMessage((prev) =>
          prev.length < 10 ? prev + "." : "Loading",
        );
      }, 500);
      return () => clearInterval(interval);
    }
  }, [recordingStatus]);

  /**
   * Handles the Start button click event to initiate recording.
   */
  const handleStartClick = async () => {
    try {
      if (!currentVisit.visitTypeId) {
        dispatch(
          setNotification(
            createNotification(
              "No Template Selected",
              "Template required to create a note",
              "warning",
            ),
          ),
        );
      } else {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        if (recordingStatus !== "paused") {
          setElapsedTime(0);
        }
        setRecordingStatus("recording");
        startRecording();
        dispatch(setRecordingState(true));
      }
    } catch (error) {
      dispatch(
        setNotification(
          createNotification(
            "Microphone Access",
            "Please grant microphone access",
            "warning",
          ),
        ),
      );
    }
  };

  /**
   * Handles the Pause button click event to pause the recording.
   */
  const handlePauseClick = () => {
    if (!navigator.onLine) {
      dispatch(
        setNotification(
          createNotification(
            "No Internet Connection",
            "Pause cannot be clicked because you are not connected to the internet. If you leave this page, all data will be lost.",
            "warning",
          ),
        ),
      );
      return;
    }

    setRecordingStatus("loading");
    stopRecording();
    dispatch(
      setNotification(
        createNotification(
          "Analyzing the Recording",
          "Estimated 10-20 seconds",
          "info",
        ),
      ),
    );
  };

  /**
   * Handles the Resume button click event to resume recording.
   */
  const handleResumeClick = () => {
    handleStartClick();
  };

  /**
   * Handles the Finish button click event to end recording and finalize the encounter.
   */
  const handleFinishClick = () => {
    if (!navigator.onLine) {
      dispatch(
        setNotification(
          createNotification(
            "No Internet Connection",
            "Finish cannot be clicked because you are not connected to the internet. If you leave this page, all data will be lost.",
            "warning",
          ),
        ),
      );
      return;
    }
    if (!currentVisit.visitTypeId) {
      dispatch(
        setNotification(
          createNotification(
            "No Template Selected",
            "Template required to create a note",
            "warning",
          ),
        ),
      );
      return;
    }
    setIsFinishClicked(true);
    if (currentVisit.visitName === "New Encounter") {
      generateName(
        currentVisitId,
        transcript,
        currentVisit.visitAdditionalPatientContext,
        dispatch,
        setVisit,
        setNotification,
      );
    }
    if (!recording) {
      handleTranscriptAvailable();
    } else {
      setRecordingStatus("loading");
      stopRecording();
      dispatch(
        setNotification(
          createNotification(
            "Analyzing the Recording",
            "Estimated 10-20 seconds",
            "info",
          ),
        ),
      );
    }
  };

  /**
   * Handles the availability of the transcript and updates the visit with notes.
   */
  async function handleTranscriptAvailable() {
    const updatedTranscript = `${currentVisit.visitTranscript}${transcript.text}`;
    await dispatch(
      setVisit(currentVisitId, { visitTranscript: updatedTranscript }),
    );
    await dispatch(setRecordingState(false));

    if (isFinishClicked || recordingStatus === "paused") {
      const visitType = visitTypes.find(
        (type) => type._id === currentVisit.visitTypeId,
      );
      const isTranscriptInsufficient =
        updatedTranscript.split(" ").length < 10 &&
        !currentVisit.visitAdditionalPatientContext;
      const newNotes = visitType.noteTypes.map((noteType) => ({
        _id: generateId(),
        noteName: noteType.noteTypeName,
        noteBody: isTranscriptInsufficient
          ? "[not enough data; insufficient transcript]"
          : "",
        noteTypeId: noteType._id,
        noteFirstTimeGenerated: !isTranscriptInsufficient,
        noteIsVisible: true,
      }));
      await dispatch(
        setVisit(currentVisitId, { visitFinished: true, notes: newNotes }),
      );
      await dispatch(setCurrentState({ currentTab: "NOTES" }));
    } else {
      setRecordingStatus("paused");
    }
  }

  /**
   * Utility to create notifications
   */
  function createNotification(name, description, status) {
    return {
      name,
      description,
      status,
      duration: 5000,
      isClosable: true,
    };
  }

  /**
   * Formats the time from seconds to MM:SS format.
   */
  function formatTime(seconds) {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  }

  return (
    <div className="recordingcontainer-recordingcontainer">
      {recordingStatus === "start" &&
      currentVisit.visitAdditionalPatientContext !== "" ? (
        <div className="recordingcontainer-startfinishcontainer">
          <Button
            text="Generate"
            image="/icons/rocket.svg"
            rootClassName="recordingcontainer-50button"
            onClick={handleFinishClick}
          />
          <DarkButton
            text="Start"
            image="/icons/microphone-white.svg"
            rootClassName="recordingcontainer-50button"
            onClick={handleStartClick}
          />
        </div>
      ) : recordingStatus === "start" ? (
        <DarkButton
          text="Start recording"
          image="/icons/microphone-white.svg"
          rootClassName="button-root-class-name3"
          onClick={handleStartClick}
        />
      ) : recordingStatus === "recording" ? (
        <div className="recordingcontainer-pausefinishcontainer">
          <RecordingButton
            onClick={handlePauseClick}
            text={formatTime(elapsedTime)}
          />
          <FinishButton onClick={handleFinishClick} />
        </div>
      ) : recordingStatus === "paused" ? (
        <div className="recordingcontainer-pausefinishcontainer">
          <PausedButton
            onClick={handleResumeClick}
            text={formatTime(elapsedTime)}
          />
          <FinishButton onClick={handleFinishClick} />
        </div>
      ) : (
        recordingStatus === "loading" && (
          <Button
            text={loadingMessage}
            rootClassName="button-root-class-name3 disabled"
          />
        )
      )}
    </div>
  );
};

RecordingContainerNotEnglish.propTypes = {
  // Add PropTypes if required
};

export default RecordingContainerNotEnglish;
