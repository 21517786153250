/**
 * External Dependencies
 */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

/**
 * Internal Components
 */
import Dropdown from "../../shared/dropdown/dropdown";

/**
 * Internal Dependencies
 * - Styles
 * - Redux Actions
 */
import "./visitnamecontainer.css";
import {
  setCurrentState,
  setVisit,
  setVisitType,
  deleteVisitType,
} from "../../../redux/actions";

/**
 * VisitNameContainer Component
 * @returns {JSX.Element} VisitNameContainer component
 */
const VisitNameContainer = () => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @currentTab - Current tab from the global state.
   * @currentVisitId - Current visit ID from the global state.
   * @currentVisitTypeId - Current visit type ID from the global state.
   * @currentVisit - Current visit based on the current visit ID.
   * @visitTypes - Visit types from the global state.
   * @currentVisitType - Current visit type based on the current visit type ID.
   * @isEditing - State for editing the visit name.
   * @name - State for the visit name.
   * @setName - Function to update the visit name state.
   * @showDropdown - State for showing the dropdown.
   * @setShowDropdown - Function to update the show dropdown state.
   * @dropdownRef - Reference to the dropdown DOM element.
   * @inputRef - Reference to the input DOM element.
   */
  const dispatch = useDispatch();
  const currentTab = useSelector(
    (state) => state.globalData.currentState.currentTab,
  );
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const currentVisitTypeId = useSelector(
    (state) => state.globalData.currentState.currentVisitTypeId,
  );
  const currentVisit = useSelector((state) =>
    state.globalData.userData.visits.find(
      (visit) => visit._id === currentVisitId,
    ),
  );
  const visitTypes = useSelector(
    (state) => state.globalData.userData.visitTypes,
  );
  const currentVisitType = visitTypes.find(
    (visitType) => visitType._id === currentVisitTypeId,
  );

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  // Update the name state based on the current tab and visit information
  useEffect(() => {
    if (currentTab === "TEMPLATES") {
      setName(currentVisitType?.visitTypeName || "");
    } else {
      setName(currentVisit?.visitName || "");
    }
  }, [currentTab, currentVisitType, currentVisit]);

  // Handle outside click to close dropdown
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // Select the entire visit name when editing
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.select();
    }
    // if (!isEditing) {
    //   if (name === '' && currentTab === 'TEMPLATES') {
    //     dispatch(setVisitType(currentVisitTypeId, { visitTypeName: 'Untitled Template' }));
    //   } else if (name === '' && currentTab !== 'TEMPLATES') {
    //     dispatch(setVisit(currentVisitId, { visitName: 'New Encounter' }));
    //   }
    // }
  }, [isEditing]);

  /**
   * Handles the click event to enable editing of the visit name.
   */
  const handleNameClick = () => {
    setShowDropdown(false);
    setIsEditing(true);
  };

  /**
   * Handles the change event for the visit name input.
   * @param {object} event - The change event.
   */
  const handleNameChange = (event) => {
    setName(event.target.value);
    if (currentTab === "TEMPLATES") {
      dispatch(
        setVisitType(currentVisitTypeId, { visitTypeName: event.target.value }),
      );
    } else {
      dispatch(setVisit(currentVisitId, { visitName: event.target.value }));
    }
  };

  /**
   * Handles the key press event for the visit name input.
   * @param {object} event - The key press event.
   */
  const handleNameKeyPress = (event) => {
    if (event.key === "Enter") {
      setIsEditing(false);
      if (currentTab === "TEMPLATES") {
        dispatch(setVisitType(currentVisitTypeId, { visitTypeName: name }));
      } else {
        dispatch(setVisit(currentVisitId, { visitName: name }));
      }
    }
  };

  /**
   * Toggles the visibility of the dropdown.
   */
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  /**
   * Handles the selection of a visit type from the dropdown.
   * @param {string} newVisitTypeId - The ID of the selected visit type.
   */
  const handleVisitTypeClick = (newVisitTypeId) => {
    dispatch(setVisit(currentVisitId, { visitTypeId: newVisitTypeId }));
    setShowDropdown(false);
  };

  /**
   * Handles the creation of a new visit type.
   */
  const handleNewVisitTypeClick = () => {
    dispatch(
      setCurrentState({
        currentVisitId: "",
        currentNoteId: "",
        currentTab: "TCENTER",
      }),
    );
    setShowDropdown(false);
  };

  /**
   * Handles the creation of a new visit type.
   */
  const handleTranscriptClick = () => {
    dispatch(setCurrentState({ currentTab: "TRANSCRIPT" }));
    setShowDropdown(false);
  };

  /**
   * Handles the creation of a new visit type.
   */
  const handleNotesClick = () => {
    dispatch(setCurrentState({ currentTab: "NOTES" }));
    setShowDropdown(false);
  };
  /**
   * Handle print click
   */
  const handlePrintClick = () => {
    // Check if currentVisit exists
    if (
      !currentVisit ||
      !currentVisit.notes ||
      currentVisit.notes.length === 0
    ) {
      console.log("No data available to print.");
      return;
    }

    const { visitName, notes } = currentVisit;

    const printFrame = document.createElement("iframe");
    printFrame.style.position = "absolute";
    printFrame.style.top = "-9999px";
    document.body.appendChild(printFrame);

    const printDocument =
      printFrame.contentDocument || printFrame.contentWindow.document;

    let printableContent = `
      <div>
        <br>
        <p style="font-size: 14px; font-weight: bold; margin: 0;">Name: ${visitName}</p>
        <br>
      </div>
    `;

    notes.forEach((note) => {
      const noteBodyWithLineBreaks = note.noteBody.replace(/\n/g, "<br>");
      printableContent += `
        <div>
          <p style="font-size: 14px; font-weight: bold; margin: 0; line-height: 2;">${note.noteName}</p>
          <p style="font-size: 12px; font-weight: normal; margin: 0 0 12px 0; line-height: 1;">${noteBodyWithLineBreaks}</p>
        </div>
      `;
    });

    printDocument.write(`
      <html>
        <body style="font-family: Arial, sans-serif;">
          ${printableContent}
        </body>
      </html>
    `);
    printDocument.close();
    printFrame.contentWindow.focus();
    printFrame.contentWindow.print();
  };

  /**
   * Handles the deletion of a template.
   * @param {string} visitTypeId - The ID of the visit type to be deleted.
   */
  const handleDeleteClick = (visitTypeId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this template?",
    );
    if (isConfirmed) {
      dispatch(deleteVisitType(visitTypeId));
      dispatch(
        setCurrentState({
          currentVisitId: "",
          currentNoteId: "",
          currentVisitTypeId: "",
          currentTab: "TCENTER",
        }),
      );
    }
  };

  const buttonList =
    currentTab === "TEMPLATES"
      ? [
          {
            image: "/icons/pencil1.svg",
            text: "Edit name",
            onClick: handleNameClick,
          },
          {
            image: "/icons/trash.svg",
            text: "Delete template",
            onClick: () => {
              handleDeleteClick(currentVisitTypeId);
            },
          },
        ]
      : window.innerWidth <= 740
        ? [
            {
              image: "/icons/pencil1.svg",
              text: "Edit name",
              onClick: handleNameClick,
            },
            {
              image: "/icons/transcript.svg",
              text: "Transcript",
              onClick: handleTranscriptClick,
            },
            {
              image: "/icons/notes.svg",
              text: "Notes",
              onClick: handleNotesClick,
            },
            {
              image: "/icons/outbound.svg",
              text: "Print note",
              onClick: handlePrintClick,
            },
          ]
        : [
            {
              image: "/icons/pencil1.svg",
              text: "Edit name",
              onClick: handleNameClick,
            },
            {
              image: "/icons/outbound.svg",
              text: "Print note",
              onClick: handlePrintClick,
            },
          ];

  const sectionList =
    currentTab !== "RECORD" && currentTab !== "TEMPLATES"
      ? [
          {
            name: "TEMPLATES",
            labelImage: "/icons/plus.svg",
            labelAction: handleNewVisitTypeClick,
            options: visitTypes
              .filter(
                (visitType) =>
                  visitType.visitTypeState !== "CREATING" &&
                  visitType.visitTypeState !== "ERROR",
              )
              .sort((a, b) => {
                if (
                  a.visitTypeState === "DEFAULT" &&
                  b.visitTypeState !== "DEFAULT"
                )
                  return 1;
                if (
                  a.visitTypeState !== "DEFAULT" &&
                  b.visitTypeState === "DEFAULT"
                )
                  return -1;
                return 0;
              })
              .map((visitType) => ({
                text:
                  visitType.visitTypeState === "DEFAULT"
                    ? `${visitType.visitTypeName} (HALO)`
                    : visitType.visitTypeName,
                image: "/icons/checkmark.svg",
                onClick: () => handleVisitTypeClick(visitType._id),
                selected: visitType._id === currentVisit?.visitTypeId,
              })),
          },
        ]
      : [];

  return (
    <div className="visitnamecontainer-visitnamecontainer">
      {!(window.innerWidth <= 740 && currentTab === "RECORD") && (
        <>
          {isEditing ? (
            <input
              type="text"
              value={name}
              onChange={handleNameChange}
              onKeyPress={handleNameKeyPress}
              onBlur={() => setIsEditing(false)}
              autoFocus
              ref={inputRef}
              className="input3"
            />
          ) : (
            <div
              className="visitnamecontainer-visitname"
              onClick={
                currentTab === "RECORD" || currentTab === "TEMPLATES"
                  ? handleNameClick
                  : toggleDropdown
              }
            >
              <span className="visitnamecontainer-name tl4">{name}</span>
              <img
                src={
                  currentTab === "RECORD" || currentTab === "TEMPLATES"
                    ? "/icons/pencil1.svg"
                    : "/icons/down.svg"
                }
                className="visitnamecontainer-image img4"
              />
            </div>
          )}
          {showDropdown && (
            <div ref={dropdownRef}>
              <Dropdown
                buttonList={buttonList}
                sectionList={sectionList}
                showBorder={true}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

VisitNameContainer.defaultProps = {
  name: "New Encounter",
};

VisitNameContainer.propTypes = {
  name: PropTypes.string,
};

export default VisitNameContainer;
