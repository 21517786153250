import { apiAskAssistantStream } from "./apiService";

/**
 * Generates the patient's name using AI assistant.
 * @param {string} currentVisitId - The current visit ID.
 * @param {string} visitTranscript - The visit transcript.
 * @param {string} visitAdditionalContext - Additional patient context.
 * @param {function} dispatch - Redux dispatch function to send actions to the store.
 * @param {function} setVisit - Function to update the visit state.
 * @param {function} setNotification - Function to trigger notifications.
 * @returns {Promise<object>} - The result of the name generation.
 */
export const generateName = async (
  currentVisitId,
  visitTranscript,
  visitAdditionalContext,
  dispatch,
  setVisit,
  setNotification,
) => {
  const instructions = "";

  const message = `
<transcript>${visitTranscript}</transcript>
<additional_context>${visitAdditionalContext}</additional_context>
Extract the patient’s name from the <transcript> and/or <additional_context> and output it within <name>{Name here}</name> XML tags. 
Capitalize the name accordingly (ex. First Last).
If no name is found in the transcript, output <name>New Encounter</name>.
FNI = First Name Initial
LNI = Last Name Initial
If there are multiple patients in the transcript, ouput <name>FNI1.LNI1, FNI2.LNI2, ...</name>
ONLY HAVE ONE SINGLE <name> TAG!!!! IT MAY HAVE MULTIPLE NAMES WITHIN IT.
The output should consist only of the <name> XML tag, with no additional text.
`;

  const modelType = "HAIKU";
  let accumulatedData = "";

  return new Promise((resolve, reject) => {
    /**
     * Generates instructions for the AI assistant and handles the response.
     * @param {string} currentInstructions - The current instructions to the AI assistant.
     * @param {string} continuationMessage - The message to continue from if needed.
     */
    const generateInstructions = (currentInstructions, continuationMessage) => {
      apiAskAssistantStream(
        currentInstructions,
        continuationMessage,
        modelType,
        (data) => {
          if (data !== "Run completed") {
            accumulatedData += data;
          } else {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(accumulatedData, "text/xml");
            const nameElement = xmlDoc.getElementsByTagName("name")[0];
            const name = nameElement
              ? nameElement.textContent.trim()
              : "New Encounter";

            dispatch(
              setVisit(currentVisitId, {
                visitName: name,
              }),
            );

            resolve({ success: true, name });
          }
        },
        (error) => {
          console.error("Error generating name:", error);
          reject({ success: false, error });
        },
      );
    };

    generateInstructions(instructions, message);
  });
};
